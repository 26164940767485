var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "p-2" },
        [
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("integrationInstructions.cleaningSettingModal.title")
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-2",
                    attrs: {
                      show: _vm.setCleaningSettingSuccess,
                      dismissible: "",
                      variant: "success",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.cleaningSettingModal.success"
                        )
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "mt-2",
                    attrs: {
                      show: _vm.setCleaningSettingError,
                      dismissible: "",
                      variant: "danger",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "integrationInstructions.cleaningSettingModal.error"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mb-4 mt-3" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "integrationInstructions.cleaningSettingModal.description"
                  )
                ) +
                " "
            ),
          ]),
          _vm._l(this.cleaningScoreThresholds, function (score, scoreIndex) {
            return _c(
              "div",
              { key: scoreIndex, staticClass: "row mb-1 align-items-center" },
              [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: score.isEnable,
                        expression: "score.isEnable",
                      },
                    ],
                    staticClass: "text-primary",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(score.isEnable)
                        ? _vm._i(score.isEnable, null) > -1
                        : score.isEnable,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = score.isEnable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(score, "isEnable", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                score,
                                "isEnable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(score, "isEnable", $$c)
                        }
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-md-7" }, [
                  score.name !== "Item Battery Score"
                    ? _c("span", [_vm._v(" " + _vm._s(score.name) + " ")])
                    : _vm._e(),
                  score.name === "Item Battery Score"
                    ? _c("span", [_vm._v(" Grid Question Score ")])
                    : _vm._e(),
                  score.key !== "R" && score.key !== "IBS"
                    ? _c("span", [_vm._v(" (" + _vm._s(score.key) + ") ")])
                    : _vm._e(),
                  score.key === "IBS"
                    ? _c("span", [_vm._v(" (GQS) ")])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    !score.isEnable
                      ? _c("b-form-input", {
                          staticStyle: {
                            background: "#f5f5f5",
                            "-webkit-appearance": "none",
                            "-moz-appearance": "none",
                          },
                          attrs: {
                            disabled: "",
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                          },
                          model: {
                            value: score.threshold,
                            callback: function ($$v) {
                              _vm.$set(score, "threshold", _vm._n($$v))
                            },
                            expression: "score.threshold",
                          },
                        })
                      : _c("b-form-input", {
                          attrs: {
                            type: "number",
                            min: 0,
                            max: 100,
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                            formatter: _vm.formatScore,
                          },
                          model: {
                            value: score.threshold,
                            callback: function ($$v) {
                              _vm.$set(score, "threshold", _vm._n($$v))
                            },
                            expression: "score.threshold",
                          },
                        }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c("div", { staticClass: "row mt-5" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSaveCleaningSettings()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.cleaningSettingModal.buttons.save"
                        )
                      ) + " "
                    ),
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "light" },
                    on: {
                      click: function ($event) {
                        return _vm.clickCancel()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrationInstructions.cleaningSettingModal.buttons.cancel"
                        )
                      ) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }