<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileService from "@/api/services/datafile.service.js";

export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      /******** Data Variables ******/
      datafileId: null,
      cleaningScoreThresholds: null,
      /******** Operational Variables *******/
      isLoading: false,
      displaySaveButton: false,
      setCleaningSettingError: false,
      setCleaningSettingSuccess: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async created() {
    this.isLoading = true;
    this.datafileId = this.$route.query.datafileId;

    // Get Cleaning Setting and set top the local varibles
    await this.getDatafileCleaningSettings(this.datafileId);
  },
  methods: {
    async getDatafileCleaningSettings(datafileId) {
      const response = await DatafileService.getDatafileCleaningSettings(
        datafileId
      );

      if (response) {
        this.cleaningScoreThresholds = response.cleaningSettings.scoreThresholds;
      }

      this.isLoading = true;
    },
    clickCancel() {
      this.$emit("close");
    },
    async clickSaveCleaningSettings() {
      this.isLoading = true;

      const response = await DatafileService.editCleaningSettings(this.datafileId, this.cleaningScoreThresholds)

      if (response.status === "SUCCESS") {
          this.isLoading = false;
          this.setCleaningSettingSuccess = true
        } else {
          this.isLoading = false;
          this.setCleaningSettingError = true;
        }
    }
  }
}
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >

    <div class="p-2">
      <h6> {{$t("integrationInstructions.cleaningSettingModal.title")}} </h6>

      <!-- Alerts and Info -->
      <div class="row">
        <div class="col-md-12">
          <!-- Successfully Set Cleaning Threshold Msg -->
          <b-alert
            :show="setCleaningSettingSuccess"
            dismissible
            class="mt-2"
            variant="success"
            >{{$t("integrationInstructions.cleaningSettingModal.success")}}</b-alert
          >
        </div>

        <!-- Error Set Cleaning Threshold Msg -->
        <div class="col-md-12">
          <b-alert
            :show="setCleaningSettingError"
            dismissible
            class="mt-2"
            variant="danger"
          >
          {{$t("integrationInstructions.cleaningSettingModal.error")}}
          </b-alert>
        </div>
      </div>

      <div class="mb-4 mt-3">
        {{$t("integrationInstructions.cleaningSettingModal.description")}}
      </div>

      <div
        class="row mb-1 align-items-center"
        v-for="(score, scoreIndex) in this.cleaningScoreThresholds"
        :key="scoreIndex"
      >
        <div class="col-md-1">
          <input
            class="text-primary"
            type="checkbox"
            v-model="score.isEnable"
          />
        </div>
        <div class="col-md-7">
          <span v-if="score.name !== 'Item Battery Score'"> {{ score.name }} </span>
          <span v-if="score.name === 'Item Battery Score'"> Grid Question Score </span>
          <span v-if="score.key !== 'R' && score.key !== 'IBS'">
            ({{ score.key }})
          </span>
          <span v-if="score.key === 'IBS'">
            (GQS)
          </span>
        </div>
        <div class="col-md-4">
          <b-form-input
            v-if="!score.isEnable"
            v-model.number="score.threshold"
            disabled
            style="
              background: #f5f5f5;
              -webkit-appearance: none;
              -moz-appearance: none;
            "
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
          >
          </b-form-input>
          <b-form-input
            v-else
            v-model.number="score.threshold"
            type="number"
            :min="0"
            :max="100"
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
            :formatter="formatScore"
          >
          </b-form-input>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12"> 
          <b-button variant="success" @click="clickSaveCleaningSettings()"
            >{{$t("integrationInstructions.cleaningSettingModal.buttons.save")}}
          </b-button>
          <b-button class="ml-2" variant="light" @click="clickCancel()"
            >{{$t("integrationInstructions.cleaningSettingModal.buttons.cancel")}}
          </b-button>
        </div>
      </div>        
    </div>
  </b-modal>
</template>